import React from 'react';
import Form from './components/Form';
import './css/Form.css';

function App() {
  return (
    <div>
      <Form />
    </div>
  );
}

export default App;
